import config from 'config';
import { useState } from 'react';

class Props {
    src: string;
    alt: string;
    className: string;
}

const Image = (props: Props) => {
    const defaultImageUrl = 'assets/default-image.png';
    const imageUrl = `${config.baseUrl}/${props.src ?? defaultImageUrl}`;
    const [src, setSrc] = useState(imageUrl);
    return <img 
        src={src} 
        alt={props.alt} 
        className={props.className}
        onError={() => { setSrc(`${config.baseUrl}/${defaultImageUrl}`) }} 
    /> 
}

export default Image;