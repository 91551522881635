import axios from "axios";
import config from "config";
// const https = require('https')

const axiosInstance = axios.create({
  baseURL: config.baseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: process.env.NODE_ENV === 'production' ? true : false,
  // }),
  timeout: 30000,
});

const CancelToken = axios.CancelToken;

export const getCancelTokenSource = () => CancelToken.source();

export default axiosInstance;
