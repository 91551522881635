
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from 'components/home/home';
import SetRating from 'components/set-rating/set-rating';
import ManigaSetNext from 'components/maniga-set-next/maniga-set-next';

import './App.css';

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Routes>
        <Route index element={<Home />} />
        <Route path='set-rating' element={<SetRating />} />
        <Route path='maniga-set-next' element={<ManigaSetNext />} />
      </Routes>
    </div>
  );
}

export default App;
