import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { useTelegram } from 'hooks/useTelegram';

export const TelegramRouter = ({ children }: any) => {
    const [currentRoute, setCurrentRoute] = useState('/');
    const { start_param } = useTelegram();
  
    useEffect(() => {
      const routeString = (start_param ?? localStorage.getItem("route")).split("_")[0];
      const storedRoute = `/${routeString}`;
      if (storedRoute && storedRoute !== currentRoute) {
        setCurrentRoute(storedRoute);
      }
    }, [currentRoute, start_param]);

    const navigatorMock: any = {};
    return (
        <Router location={currentRoute} navigator={navigatorMock}>
            {children}
        </Router>
    );
}