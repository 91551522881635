import React from 'react';
import './home.css';

const Home = () => {
  return (
    <div>
      Home
    </div>
  );
}

export default Home;