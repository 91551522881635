import axios from 'axios-instance'
import { ManigaUserModel } from './models/maniga-user.model';
import { SetManigaModel } from './models/set-maniga.model';

export default class ManigaService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async setNextManiga(model: SetManigaModel) {
        const { data } = await axios.post(
            `api/TelegramWebApp/SetNextManiga`,
            model,
            this.axiosConfig
        );

        return data;
    }

    public static async getChatUsers(chatId: string, initData: string): Promise<ManigaUserModel[]> {
        const { data } = await axios.post(
            `api/TelegramWebApp/ManigaUsersList?chatId=${chatId}`,
            {
                initData: initData
            },
            this.axiosConfig
        );

        return data;
    }
}