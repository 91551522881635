import axios from 'axios-instance'
import { SetRatingModel } from './models/set-rating.model';
import { AnonimPlayerModel } from './models/anonim-player.model';

export default class RatingsService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async setRating(model: SetRatingModel) {
        const { data } = await axios.post(
            `api/TelegramWebApp/SetRating`,
            model,
            this.axiosConfig
        );

        return data;
    }

    public static async getAnonimPlayer(voteId: string, initData: string): Promise<AnonimPlayerModel> {
        const { data } = await axios.post(
            `api/TelegramWebApp/GetAnonimPlayer?voteId=${voteId}`,
            {
                initData: initData
            },
            this.axiosConfig
        );

        return data;
    }
    
}