import React, { useCallback, useEffect, useState } from 'react';
import { SetRatingModel } from 'api/ratings/models/set-rating.model';
import RatingsService from 'api/ratings/ratings.service';
import { useTelegram } from 'hooks/useTelegram';
import { AnonimPlayerModel } from 'api/ratings/models/anonim-player.model';
import Loading from 'components/shared/loading/loading';
import styled from 'styled-components';
import { Slider } from '@mui/material';
import { MdStar } from 'react-icons/md';

const SetRating = () => {
  const { tg, start_param, user, onClose } = useTelegram();
  const [rating, setRating] = useState<number>(null);
  const [voteId, setVoteId] = useState('');
  const [player, setPlayer] = useState<AnonimPlayerModel>(null);
  const [loading, setLoading] = useState(false);

  const onSendData = useCallback(async () => {
    try {
        tg.MainButton.disable();
        const data: SetRatingModel = {
            chatId: player.chatId,
            voteId: voteId,
            rating: rating,
            telegramUserId: user?.id,
            initData: tg.initData
        };
        await RatingsService.setRating(data);
        onClose();
    } catch(e: any){
        tg.MainButton.enable();
    }
  }, [rating, player?.chatId, user?.id, tg, voteId, onClose])

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    return () => {
        tg.offEvent('mainButtonClicked', onSendData);
    }
  }, [onSendData, tg])

  useEffect(() => {
    tg.MainButton.setParams({
        text: 'Зберегти'
    })

    const parsedVoteId = (start_param ?? localStorage.getItem("route")).split("_")[1];

    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await RatingsService.getAnonimPlayer(parsedVoteId, tg.initData);
        setPlayer(data);
        setRating(data.rating);
      } catch(err) {
        console.error(err);
      } finally{
        setLoading(false);
      }
    }

    fetchData();

    tg.ready();

    setVoteId(parsedVoteId);
  }, [start_param, tg]);

  useEffect(() => {
    if(!rating){
        tg.MainButton.hide();
    } else {
        tg.MainButton.show();
    }
  }, [rating, tg.MainButton])

  if(loading){
    return <Loading />
  }

  return (
    <StyledSection className='form-container'>
      <div className='form'>
        <div className='player__header'>
          <div className='player__name'>
            {player?.name}
          </div>
          <div className='player__rating'>
            <MdStar /><div>{rating}</div>
          </div>
        </div>
          <Slider
            min={0}
            max={10}
            defaultValue={rating ?? 5}
            step={0.05}
            valueLabelDisplay="auto"
            onChangeCommitted={(event, value) => {
              if(Array.isArray(value))
                return;
              
              setRating(value);
            }}
          />
      </div>
    </StyledSection>
  );
}

const StyledSection = styled.div`
  .player {
    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__name {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 20px;
    }

    &__rating {
      font-size: 20px;
      display: flex;
      align-content: center;
      column-gap: 2px;
    }
  }

`

export default SetRating;