import React, { useCallback, useEffect, useState } from 'react';
import { useTelegram } from 'hooks/useTelegram';
import { SetManigaModel } from 'api/maniga/models/set-maniga.model';
import ManigaService from 'api/maniga/maniga.service';
import { ManigaUserModel } from 'api/maniga/models/maniga-user.model';
import Loading from 'components/shared/loading/loading';
import Image from 'components/shared/image/image';
import styled from 'styled-components';

const ManigaSetNext = () => {
  const { 
    tg, 
    start_param,
    user,
    onClose 
  } = useTelegram();
  const [selectedUser, setSelectedUser] = useState<ManigaUserModel>(null);
  const [players, setPlayers] = useState<ManigaUserModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [chatId, setChatId] = useState('');
  const [keyword, setKeyword] = useState(null);

  const onSendData = useCallback(async () => {
    try {
        tg.MainButton.disable();
        const data: SetManigaModel = {
            chatId: chatId,
            userId: `${selectedUser?.id}`,
            telegramUserId: user?.id,
            initData: tg.initData
        };
        await ManigaService.setNextManiga(data);
        onClose();
    } catch(e: any){
        tg.MainButton.enable();
    }
  }, [selectedUser, chatId, tg, user?.id, onClose])

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    return () => {
        tg.offEvent('mainButtonClicked', onSendData);
    }
  }, [onSendData, tg])

  useEffect(() => {
    tg.MainButton.setParams({
        text: 'Зберегти'
    });

    const parsedChatId = (start_param ?? localStorage.getItem("route")).split("_")[1];

    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await ManigaService.getChatUsers(parsedChatId, tg.initData);
        setPlayers(data);
      } catch(err){
        console.error(err)
      } finally{
        setLoading(false);
      }
    }

    fetchData();

    setChatId(parsedChatId);

    tg.ready();
  }, [start_param, tg]);

  useEffect(() => {
    if(!selectedUser){
        tg.MainButton.hide();
    } else {
        tg.MainButton.show();
    }
  }, [selectedUser, tg.MainButton])

  if(loading){
    return <Loading />
  }

  const filteredPlayers = players.filter(x => !keyword 
    || (
      `${x.firstName} ${x.lastName ?? ''}`.toLowerCase().includes(keyword.toLowerCase()) 
      || x.friendlyName?.toLowerCase().includes(keyword.toLowerCase())
      )
    );

  return (
    <StyledSection>
      <div className='maniga-container'>
        <div className='maniga__search'>
          <input 
            type='search' 
            placeholder="Введіть ім'я"
            onChange={(e) => {
              setKeyword(e.target.value?.trim())
            }} 
          />
        </div>
        <div className='maniga__players'>
          {filteredPlayers.map(x => (
                <div 
                  className='player-item' 
                  key={x.id} 
                  onClick={() => { setSelectedUser(x) }}
                >
                    <div>
                        <Image 
                          className='player-image' 
                          src={`images/users/m/${x.imagePath}`} 
                          alt='' 
                        />
                    </div>
                    <div>
                        {x.friendlyName ?? `${x.firstName} ${x.lastName ?? ''}`}
                    </div>
                </div>
            ))}
        </div>
      </div>
    </StyledSection>
  );
}

const StyledSection = styled.div`
  .maniga-container {
    display: grid;
    grid-template-areas: "search"
          "players";
    grid-template-rows: auto auto 1fr;
    row-gap: 15px;
    background-color: var(--tg-theme-secondary-bg-color);
    color: var(--tg-theme-text-color);
    height: var(--tg-viewport-height);
    padding: 20px;
    font-size: 18px;
  }

  .maniga {
    &__search {
      grid-area: search;

      input {
        width: 100%;
        padding: 0.375rem 0.75rem;
        background-color: var(--tg-theme-bg-color);
        color: var(--tg-theme-text-color);
        border: 1px solid var(--tg-theme-hint-color);
        border-radius: 0.25rem;
        font-size: 18px;
        line-height: 1.5;
      }
    }

    &__players {
      grid-area: players;
      overflow-y: auto;
      border-radius: 10px;
      background-color: var(--tg-theme-bg-color);

      scrollbar-width: auto;
      scrollbar-color: #dadada transparent;
      &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
      }
      &::-webkit-scrollbar-track {
          background: transparent;
      }
      &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background: #dadada;
      }
      &::-webkit-scrollbar-thumb:hover {
          background: #b8b8b8;
      }
    }
  }

  .player-item {
    display: flex;
    column-gap: 16px;
    align-items: center;
    cursor: pointer;
    padding: 5px 15px;

    &:hover {
      background-color: var(--tg-theme-hint-color);
    }
  }

  .player-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    background-color: #fff;
  }
`

export default ManigaSetNext;