const tg = (window as any).Telegram.WebApp;

export function useTelegram(){
    const onClose = () => {
        tg.close();
    }

    const onToggleButton = () => {
        if(tg.MainButton.isVisible) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }

    return {
        onClose,
        onToggleButton,
        tg,
        chat: tg.initDataUnsafe?.chat,
        user: tg.initDataUnsafe?.user,
        chat_instance: tg.initDataUnsafe?.chat_instance,
        chat_type: tg.initDataUnsafe?.chat_type,
        start_param: tg.initDataUnsafe?.start_param
    }
}